import tracker from 'utils/tracking';
//import { useFlags } from 'launchdarkly-react-client-sdk';
import './AgeVerify.scss';
import { useState } from 'react';
import { AGE_VERIFY_REDIRECT } from '../../constants';

const AgeVerify = () => {
  const { href } = window.location;
  const showAgeVerify = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const fromLocalStorage = window.localStorage.getItem('isVerified');
  const handleYes = () => {
    tracker.track('age_verify.yes.clicked');
    window.localStorage.setItem('isVerified', true);
    setIsVerified(true);
  };
  const handleNo = () => {
    tracker.track('age_verify.no.clicked');
    window.location.href = AGE_VERIFY_REDIRECT;
  };

  if (!showAgeVerify || href.includes('promotions')) {
    return null;
  }
  if (isVerified || fromLocalStorage === 'true') {
    return null;
  }

  return (
    <div className="modalOverlay">
      <div className="modal">
        <h2>
          Are you 21 or older?
        </h2>
        <div className="actions">
          <button
            type="button"
            onClick={ handleNo }
          >
            No
          </button>
          <button
            type="button"
            className="primary"
            onClick={ handleYes }
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerify;
